import {StyleExtractor, get} from "@kubric/utils";
import {graphql} from "gatsby";
import React, {useMemo, useState} from "react";
import ChevronLeft from "react-feather/dist/icons/chevron-left";
import ChevronRight from "react-feather/dist/icons/chevron-right";

import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import {Skeleton} from "../../components/commons/Skeleton";
import {BottomCta} from "../../components/modemagic/BottomCta";
import BlogCard from "../../components/pages/feed/CardsSection/cards/BlogCard";
import EventCard from "../../components/pages/feed/CardsSection/cards/EventCard";
import fieldMap from "../../components/pages/feed/fieldmap";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {commonJoinList} from "../../lib/actions";
import checkWithinLastNDays from "../../utils/checkWithinLastNDays";
import {hubSpot} from "../../utils/hubSpot";
import {parseJSON} from "../../utils/jsonparser";
import getReadingTime from "../../utils/readingMinutes";
import {useScrollPercentage} from "../../utils/scrollPercentage";
import * as styles from "./styles.module.scss";

const cardType = {
  EVENT: "event",
  BLOG: "blog",
};

const cards = {
  [cardType.BLOG]: BlogCard,
  [cardType.EVENT]: EventCard,
};

const ALL_TAG = {
  name: "All",
  value: "all",
};

const FeedPage = ({
  data: {
    allSanitySeoMeta,
    blogs: {nodes: blogs} = {},
    featuredPost: {nodes: [featuredPost]} = {},
    feedPage,
    tags: {nodes: tags} = {},
  },
}) => {
  const seoData = allSanitySeoMeta.nodes[0];
  const isMobile = useMediaQuery("(max-width: 900px)");

  const styler = new StyleExtractor(styles);
  const [tagFilter, setTagFilter] = useState(ALL_TAG.value);

  const parsedFeedPage = parseJSON(feedPage, fieldMap);
  const {sections} = parsedFeedPage.data;
  // storing sort date of latest blog from all blogs
  const [latestCardSortDate, setLatestCardSortDate] = React.useState(0);
  const [showMore, setShowMore] = React.useState(false);
  // Combined card list with events and blog entries
  const cardList = useMemo(() => {
    let tempLatestCardSortDate;
    const cards = [
      ...feedPage?.events.map((event) => ({
        type: "event",
        data: event,
        sortDate: new Date(event.eventDate).getTime(),
      })),
      ...blogs.map((blog) => {
        let currentSortDate = new Date(blog.published_at).getTime();
        // getting sortDate of the latest blog
        if (
          !tempLatestCardSortDate ||
          tempLatestCardSortDate < currentSortDate
        ) {
          tempLatestCardSortDate = currentSortDate;
        }
        return {
          type: "blog",
          data: {
            ...blog,
            shouldShowNewTag: checkWithinLastNDays(blog.published_at, 5),
            readingTime: getReadingTime(blog.html),
          },
          sortDate: currentSortDate,
        };
      }),
    ];

    setLatestCardSortDate(tempLatestCardSortDate);

    return cards.filter((card) => {
      if (tagFilter === ALL_TAG.value) {
        return true;
      }
      return card.data.tags.some((tag) => tag.slug === tagFilter);
    });
  }, [tagFilter]);

  const tagsList = useMemo(
    () => [ALL_TAG, ...tags.map(({name, slug}) => ({name, value: slug}))],
    []
  );

  // Sorted list by date to get the final display order
  cardList.sort((a, b) => {
    const {sortDate: aSortDate} = a;
    const {sortDate: bSortDate} = b;
    return bSortDate - aSortDate;
  });
  const {bgColor, headingColor} = sections[0];
  const cardElmts = cardList.map(({type, data, sortDate}) => {
    const Card = cards[type];
    return (
      <Card
        data={{
          ...data,
          isNew: latestCardSortDate === sortDate || data?.shouldShowNewTag,
        }}
        feedMode
        color={headingColor}
        bgColor={bgColor}
      />
    );
  });

  const feedHeaderRef = React.useRef();
  const {scrollPercentage, getScrollPercentage} = useScrollPercentage();
  // to show header box shadow
  const [showHeaderShadow, setShowHeaderShadow] = React.useState(false);
  React.useEffect(() => {
    if (feedHeaderRef?.current) {
      // get height and distance from top of the feed header
      const {top: feedHeaderTop, height: feedHeaderHeight} =
        feedHeaderRef.current.getBoundingClientRect();
      const pageTop = window.pageYOffset;
      // offset due to page header
      const offset = -92;
      // get the percentage threshold
      const feedHeaderScrollPercentage = getScrollPercentage(
        feedHeaderTop + pageTop + feedHeaderHeight + offset
      );
      // update according to threshold
      setShowHeaderShadow(scrollPercentage >= feedHeaderScrollPercentage);
    }
  }, [feedHeaderRef?.current, scrollPercentage]);

  const tagsRef = React.useRef();
  const scrollTags = (scrollOffset) => {
    tagsRef.current.scrollLeft += scrollOffset;
  };

  const [tagsScrolledLeft, setTagsScrolledLeft] = React.useState(true);
  const [tagsScrolledEnd, setTagsScrolledEnd] = React.useState(false);

  const checkTagsScroll = () => {
    setTagsScrolledLeft(tagsRef?.current?.scrollLeft === 0);
    setTagsScrolledEnd(
      tagsRef?.current?.scrollLeft + tagsRef?.current?.offsetWidth ===
        tagsRef?.current?.scrollWidth
    );
  };

  // loading state of page
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    let timeoutId = setTimeout(() => setLoaded(true), 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Layout
      headerProps={{showHeaderShadow, alternateBg: true}}
      seoData={seoData}
    >
      <StructuredData
        type={StructuredDataType.FEED}
        data={{
          url: "https://getmason.io/feed",
          name: "Learn how design & content are driving modern commerce",
          description:
            "Case studies, how-tos, and expert insights to take you from startup to scale-up and beyond.",
          breadcrumbList: {
            items: cardList.map((card) => ({
              name: card.data.title,
              item: `https://getmason.io/blog/post/${card.data.slug}`,
            })),
          },
        }}
      />
      <div
        className={styler.get("feedHeader", "full-bleed")}
        ref={feedHeaderRef}
      >
        <BottomCta
          showLoading
          showResponse
          customClickHandler={(email) => {
            return commonJoinList({
              tags: ["Blog Subscriber"],
              email,
              list: "9ace7996c8",
              account: "mailchimp",
              onDone: () =>
                hubSpot({
                  data: {
                    email,
                    source: "FeedPage",
                    sourceUrl: window.location.href,
                  },
                }),
            });
          }}
          theme={{
            ctaSection: styler.get("ctaSection"),
            ctaDesc: styler.get("ctaDesc"),
            mmCtaContainer: styler.get("mmCtaContainer"),
          }}
          data={feedPage.hero}
          footer={false}
          showEmail
          id='subscribe-to-blog'
        />
      </div>
      <div className={styler.get("loader")} hidden={loaded}>
        <div className={styler.get("loaderFeatured")}>
          <Skeleton h={304} w={710} r='14.23px' />
          <div className={styler.get("postDetails")}>
            <div className={styler.get("postInfo")}>
              <div className={styler.get("loaderTags")}>
                <Skeleton n={2} h={42} w={[105, 125]} />
              </div>
              <div className={styler.get("postDescription")}>
                <div className={styler.get("title")}>
                  <Skeleton n={2} h={20} w={379} r='6px' />
                </div>
                <div className={styler.get("subTitle")}>
                  <Skeleton n={4} h={12} w={[352, 374, 374, 225]} r='6px' />
                </div>
              </div>
            </div>
            <div className={styler.get("postAuthor")}>
              <Skeleton h={40} w={40} r='50%' />
              <div className={styler.get("authorInfo")}>
                <Skeleton h={12} w={80} r='6px' />
                <div className={styler.get("authorDesignation")}>
                  <Skeleton n={2} h={12} w={[107, 70]} r='6px' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Skeleton h={4} w={1130} r='2px' />
        <div className={styler.get("allTags")}>
          <Skeleton n={7} h={42} w={[52, 82, 97, 121, 123, 115, 91]} />
        </div>
        <div className={styler.get("blogs")}>
          <Skeleton n={3} h={470} w={350} r='12px' />
        </div>
      </div>
      <div className={styler.get("mainContent")} hidden={!loaded}>
        {featuredPost && (
          <>
            <div className={styler.get("featuredPost")}>
              <BlogCard
                data={{
                  ...featuredPost,
                  readingTime: getReadingTime(featuredPost.html),
                }}
                theme={{feed: styler.get("featuredPostMobile")}}
                featuredPost
                feedMode={isMobile}
              />
            </div>
            <hr className='' />
          </>
        )}
        <div className={styler.get("tagsWrapper")}>
          {isMobile && (
            <>
              <ChevronLeft
                hidden={tagsScrolledLeft}
                className={styler.get("leftIcon")}
                onClick={() => scrollTags(-250)}
              />
              <ChevronRight
                hidden={tagsScrolledEnd}
                className={styler.get("rightIcon")}
                onClick={() => scrollTags(250)}
              />
            </>
          )}
          <div
            className={styler.get("tags")}
            ref={tagsRef}
            onScroll={checkTagsScroll}
          >
            {tagsList.map(({name, value}) => (
              <h5
                className={styler.get(
                  ["tag", value === tagFilter && "highlight"],
                  [name.startsWith("#") && "hidden"]
                )}
                key={value}
                onClick={() => setTagFilter(value)}
              >
                {name}
              </h5>
            ))}
          </div>
        </div>
        <div className={styler.get("cardArea", ["mt-8"])}>
          {cardElmts.splice(0, 6)}
        </div>
        {cardElmts.length > 0 && (
          <>
            <div className={`full-bleed layouted ${styler.get("ctaBetween")}`}>
              <BottomCta
                showLoading
                showResponse
                action={({email}) => {
                  return commonJoinList({
                    tags: [],
                    email,
                    list: "getmason",
                    account: "mason",
                  }).finally(() => {
                    let newWindow = window.open(
                      get(
                        feedPage,
                        "cta.cta.url",
                        "https://app.getmason.io/login"
                      ),
                      "_blank"
                    );
                    newWindow.opener = null;
                  });
                }}
                styles='md:!mb-12'
                data={feedPage.cta}
                footer={false}
                showEmail
                id='get-mason-app'
              />
            </div>
            <div className={styler.get("cardArea", ["mt-8"])}>
              {cardElmts.splice(0, 6)}
              {showMore ? (
                cardElmts
              ) : (
                <div className={styler.get("showMoreButton")}>
                  <button
                    type='button'
                    className='primary-cta withoutBorder'
                    onClick={() => setShowMore(true)}
                  >
                    Show More
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

// remove internal tags from being fetched
export const query = graphql`
  {
    allSanitySeoMeta(filter: {page: {eq: "feed"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    tags: allGhostTag(filter: {name: {regex: "/^(?!#).*/"}}) {
      nodes {
        name
        slug
      }
    }
    feedPage: sanityFeedPage(_id: {eq: "feedPage"}) {
      __typename
      events {
        _createdAt
        slug {
          current
        }
        tags
        img {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        alt
        tileImg {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        tileAlt
        eventSeries
        eventExcerpt
        eventTitle {
          label
          bold
          _key
          color {
            value
          }
        }
        eventDate
        id
        featured
      }
      hero {
        cta {
          text
          url
          responseText
        }
        belowCta
        _rawDescription
        _rawHeading
      }
      cta {
        cta {
          text
          url
          responseText
        }
        belowCta
        _rawDescription
        _rawHeading
      }
      sections {
        bgColor {
          value
        }
        headingColor {
          value
        }
      }
    }
    featuredPost: allGhostPost(
      filter: {tags: {elemMatch: {name: {eq: "#featured"}}}}
    ) {
      nodes {
        html
        localImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        og_image
        og_description
        tags {
          slug
          name
        }
        title
        authors {
          profile_image
          location
          name
          url
        }
        slug
        published_at
        updated_at
      }
    }
    blogs: allGhostPost(filter: {featured: {eq: true}}) {
      nodes {
        html
        localImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        og_image
        og_description
        tags {
          slug
          name
        }
        title
        authors {
          profile_image
          name
          url
          location
        }
        slug
        published_at
        updated_at
      }
    }
  }
`;

export default FeedPage;
