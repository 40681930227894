import {StyleExtractor} from "@kubric/utils";
import {Link} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "../styles.module.scss";

const EventCard = ({
  data: {
    slug,
    img,
    alt = "",
    tags = [],
    tileImg,
    tileAlt,
    eventTitle = [],
    eventExcerpt,
    _createdAt: createdAt,
  },
  feedMode = false,
  className,
  theme,
}) => {
  const styler = new StyleExtractor(styles, theme);
  const title = eventTitle.reduce((acc, {label = ""}) => `${acc} ${label}`, "");
  const image = tileImg || img;

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const publishDate =
    feedMode &&
    new Intl.DateTimeFormat("en-US", options).format(new Date(createdAt));

  return (
    <Link
      to={`/event/${slug.current}`}
      className={styler.get(
        ["feedCard", feedMode && "feed"],
        ["relative overflow-hidden", className]
      )}
    >
      {image && (
        <GatsbyImage
          className={feedMode ? styler.get("cardImage") : "w-full h-full"}
          image={image?.asset?.gatsbyImageData}
          alt={tileAlt || alt || "Mason"}
        />
      )}
      <div className={styler.get(["cardDetails"], [!feedMode && "w-3/4"])}>
        {/* {!!eventSeries && (
          <h4 className={styles.series}>
            {seriesType === "video" ? (
              <BookOpen size={16} color='#f5c252' />
            ) : (
              <Video size={16} color='#f5c252' />
            )}
            {eventSeries}
          </h4>
        )} */}
        <div className='flex flex-row gap-3 items-center justify-start'>
          {tags.map((tag) => (
            <OutboundLink href={`/blog/tag/${tag.slug}`}>
              <h5 className={styles.tag}>{tag}</h5>
            </OutboundLink>
          ))}
        </div>
        <h3 className={styles.title}>{title}</h3>
        {feedMode && (
          <>
            <p className={styles.description}>{eventExcerpt}</p>
            <div className={styles.meta}>
              {publishDate && <p className={styles.date}>{publishDate}</p>}
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default EventCard;
