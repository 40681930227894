import {colorField} from "../../../utils/jsonparser/fields";

export default {
  SanityFeedPage: [
    {
      name: "sections",
      fields: [colorField("bgColor"), colorField("headingColor")],
    },
  ],
};
