import {
  get,
  isFunction,
  isNullOrUndefined,
  isPlainObject,
  isUndefined,
  isValidString,
} from "@kubric/utils";

const extractValuesFromObject = (object, fields = []) =>
  fields.reduce((acc, field) => {
    if (isValidString(field) && !isNullOrUndefined(object[field])) {
      acc[field] = object[field];
    } else if (isPlainObject(field)) {
      const {name, fields, valueFrom, rename, transform} = field;
      const rawValue = isValidString(valueFrom)
        ? get(object[name], valueFrom)
        : object[name];
      let finalValue = rawValue;
      if (
        (isPlainObject(rawValue) || Array.isArray(rawValue)) &&
        fields?.length > 0
      ) {
        if (Array.isArray(rawValue)) {
          finalValue = rawValue.map((entry) =>
            extractValuesFromObject(entry, fields)
          );
        } else {
          finalValue = extractValuesFromObject(rawValue, fields);
        }
      }
      if (!isNullOrUndefined(finalValue)) {
        acc[isValidString(rename) ? rename : name] = isFunction(transform)
          ? transform(finalValue)
          : finalValue;
      }
    }
    return acc;
  }, {});

const parseData = (data, fieldMap) => {
  const {__typename: type} = data;
  const typeConfig = fieldMap[type];
  if (Array.isArray(typeConfig)) {
    return {
      type,
      data: extractValuesFromObject(data, typeConfig),
    };
  }
  return undefined;
};

export const parseJSON = (data, fieldMap) => {
  if (Array.isArray(data)) {
    return data.reduce((acc, dataPart) => {
      const parsedData = parseData(dataPart, fieldMap);
      if (!isUndefined(parsedData)) {
        acc.push(parsedData);
      }
      return acc;
    }, []);
  }
  if (isPlainObject(data)) {
    return parseData(data, fieldMap);
  }
  return data;
};
