import {StyleExtractor} from "@kubric/utils";
import * as React from "react";

import * as styles from "./styles.module.scss";

export const Skeleton = ({
  className = "",
  h = 10,
  w = 10,
  r = "4px",
  n = 1,
}) => {
  const styler = new StyleExtractor(styles);
  return (
    <>
      {Array(n)
        .fill(0)
        .map((_, index) => (
          <div
            className={styler.get("skeleton", className)}
            style={{
              height: Array.isArray(h) ? h[index] : h,
              width: Array.isArray(w) ? w[index] : w,
              borderRadius: Array.isArray(r) ? r[index] : r,
            }}
          />
        ))}
    </>
  );
};
