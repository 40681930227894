import {isObject, isString} from "@kubric/utils";
import * as React from "react";
import {Helmet} from "react-helmet";

import {
  LogoURL,
  OrganizationContactPoint,
  OrganizationName,
  OrganizationURL,
} from "../lib/constants";

export const StructuredDataType = {
  BLOG: "BLOG",
  EVENT: "EVENT",
  FEED: "FEED",
  BLOGS: "BLOGS",
  PRODUCT: "PRODUCT",
  BREADCRUMB: "BREADCRUMB",
  FAQ: "FAQ",
  DEFAULT: "DEFAULT",
};

const StructuredData = ({type = StructuredDataType.DEFAULT, data = {}}) => {
  const StructuredDataContent = React.useMemo(() => {
    // for blog details page
    if (type === StructuredDataType.BLOG) {
      return `
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": "${data.title}",
          "image": ${
            isString(data.image)
              ? `"${data.image}"`
              : `[${data.image.map((e) => `"${e}"`).join(",")}]`
          },
          "datePublished": "${data.datePublished}",
          "dateModified": "${data.dateModified}",
          "author": ${
            isObject(data.author)
              ? `{
                  "@type": "Person",
                  "name": "${data.author.name}",
                  "url": "${data.author.url}",
                  "image": "${data.author.image}"
                }`
              : `[${data.author
                  .map(
                    (e) =>
                      `{
                        "@type": "Person",
                        "name": "${e.name}",
                        "url": "${e.url}"
                      }`
                  )
                  .join(",")}
                ]`
          }
        }
      `;
    }

    // for event details page
    if (type === StructuredDataType.EVENT) {
      return `
          {
            "@context": "https://schema.org/",
            "@type": "Event",
            "name": "${data.name}",
            "startDate": "${data.startDate}",
            "endDate": "${data.startDate}",
            "eventStatus": "EventMovedOnline",
            "eventAttendanceMode": "OnlineEventAttendanceMode",
            "image": "${data.image}",
            "description": "${data.description}",
            ${
              data?.performer?.type && data?.performer?.name
                ? `"performer": {
              "@type": "${data.performer.type}",
              "name": "${data.performer.name}"
            },`
                : ""
            }
            "organizer": {
              "@type": "Organization",
              "name": "${OrganizationName}",
              "url": "${OrganizationURL}"
            },
            "location": {
              "@type": "VirtualLocation",
              "url": "${data.url}"
            }
          }
        `;
    }

    // for feed page
    if (type === StructuredDataType.FEED) {
      return `
        {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebPage",
              "@id": "${data?.id || `${data.url}/#website`}",
              "url": "${data.url}",
              "name": "${data.name}",
              "breadcrumb":{
                "@id": "${data.breadcrumbList?.id || `${data.url}/#breadcrumb`}"
              },
              "description": "${data.description}",
              "inLanguage": "${data?.inLanguage || "en-US"}"
            },{
              "@type": "BreadcrumbList",
              "name": "${data.breadcrumbList?.name || data.name}",
              "@id": "${data.breadcrumbList?.id || `${data.url}/#breadcrumb`}",
              "itemListElement": [
                ${data.breadcrumbList.items
                  .map(
                    (item, index) =>
                      `{
                      "@type": "ListItem",
                      "position": ${index + 1},
                      "name": "${item.name}",
                      "item": "${item.item}"
                    }`
                  )
                  .join(",")}
              ]
            }
          ]
        }
      `;
    }

    // for blogs page
    if (type === StructuredDataType.BLOGS) {
      return `
        {
          "@context":"https://schema.org",
          "@graph":[
            {
              "@type": "WebPage",
              "@id": "${data?.id || `${data.url}/#website`}",
              "url": "${data.url}",
              "name": "${data.name}",
              "description": "${data.description}",
              "breadcrumb": {
                "@id": "${data.breadcrumbList?.id || `${data.url}/#breadcrumb`}"
              },
              "inLanguage": "en-US"
            }, {
              "@type": "BreadcrumbList",
              "name": "${data.breadcrumbList?.name || data.name}",
              "@id": "${data.breadcrumbList?.id || `${data.url}/#breadcrumb`}",
              "itemListElement": [
                ${data.breadcrumbList.items
                  .map(
                    (item, index) =>
                      `{
                      "@type": "ListItem",
                      "position": ${index + 1},
                      "name": "${item.name}",
                      "item": "${item.item}"
                    }`
                  )
                  .join(", ")}
              ]
            }
          ]
        }
      `;
    }

    // for home, modemagic and features page
    if (type === StructuredDataType.PRODUCT) {
      const nonGenericFields = {
        home: `
          "review": [
            {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Supreeth"
              },
              "reviewBody": "Every day, you get to look at yummy food experiences in our app. Mason fuels these &quot;tasty&quot; content for our company - & users."
            }
          ],
          "offers": {
            "@type": "Offer",
            "url": "https://getmason.io/pricing/",
            "priceCurrency": "USD",
            "price": "19"
          }
        `,
        features: `
          "review": [
            {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Pete"
              },
              "reviewBody": "Really quick, effective and so easy to use. A breath of fresh air compared to so many other design or editing apps out there."
            },
            // {
            //   "@type": "Review",
            //   "reviewRating": {
            //     "@type": "Rating",
            //     "ratingValue": "5"
            //   },
            //   "author": {
            //     "@type": "Person",
            //     "name": "Shriya"
            //   },
            //   "reviewBody": "Every day, over 6 million users interact with more than one personalized creative in our apps & website, all thanks to Mason."
            // }
          ],
          "offers": {
            "@type": "Offer",
            "url": "https://getmason.io/pricing/",
            "priceCurrency": "USD",
            "price": "19"
          }
      `,

        modemagic: `
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "bestRating": "5",
            "ratingCount": "493"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://getmason.io/modemagic/#pricing",
            "priceCurrency": "USD",
            "price": "9"
          }
        `,
      };

      return `
        {
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "${data?.name || OrganizationName}",
          "applicationSubCategory": "WebApplication",
          "operatingSystem": "Windows, OSX, Linux, Android, iOS",
          "applicationCategory": "BusinessApplication",
          "browserRequirements": "requires HTML5 and JavaScript support",${
            nonGenericFields[data?.key] ?? ""
          }
        }
      `;
    }

    // to return structured data with breadcrumblist format
    if (type === StructuredDataType.BREADCRUMB) {
      return `
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          ${data?.name ? `"name":"${data.name}",` : ""}
          "itemListElement": [${data.items
            .map(
              (item, index) => `
                {
                  "@type": "ListItem",
                  "position": ${index + 1},
                  "name": "${item.name}",
                  "item": "${item.item}"
                }
              `
            )
            .join(", ")}]
        }
      `;
    }

    // for faq page
    if (type === StructuredDataType.FAQ) {
      return `
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "name": "${data.name}",
          "mainEntity": [
            ${data.list
              .map(
                ({q, a}) => `
                  {
                    "@type": "Question",
                    "name": "${q}",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${a}"
                    }
                  }
                `
              )
              .join(", ")}
          ]
        }
      `;
    }

    // default for webpages
    return `
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "${OrganizationName}",
          "url": "${OrganizationURL}",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "${OrganizationContactPoint.email}",
            "telephone": "${OrganizationContactPoint.telephone}",
            "contactType": "${OrganizationContactPoint.contactType}"
          },
          "logo": "${LogoURL}"
        }
      `;
  }, [type, data]);

  return (
    <Helmet>
      <script type='application/ld+json'>{StructuredDataContent}</script>
    </Helmet>
  );
};

export default StructuredData;
