import {StyleExtractor} from "@kubric/utils";
import React from "react";

import MMCta from "../../commons/MMCTA";
import SanityRichText from "../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const BottomCta = ({
  data: ctaSection,
  action = "mmGetStartedWithASRedirect",
  theme = {},
  showEmail,
  id,
  ...props
}) => {
  const styler = new StyleExtractor(styles, theme);
  return (
    <div
      className={`flex flex-col items-center mt-12 mb-10 md:mb-32 ${styler.get([
        "ctaSection",
      ])}`}
    >
      <SanityRichText
        blocks={ctaSection._rawHeading}
        renderContainerOnSingleChild
        className={styler.get("ctaHead")}
      />
      <SanityRichText
        blocks={ctaSection._rawDescription}
        renderContainerOnSingleChild
        className={styler.get("ctaDesc")}
      />
      <MMCta
        cta={ctaSection.cta.text}
        emailResponse={ctaSection.cta.responseText}
        id={id || "get-mm-app"}
        action={action}
        showEmail={showEmail}
        href={ctaSection.cta.url}
        theme={{container: styler.get("mmCtaContainer")}}
        {...props}
      />
    </div>
  );
};

export {BottomCta};
