const styleTransformer = (data = []) =>
  data.reduce((acc, {property, value}) => {
    acc[property] = value;
    return acc;
  }, {});

const stylesheetTransformer = (data = []) =>
  data.reduce((acc, {field, styles}) => {
    acc[field] = styles;
    return acc;
  }, {});

const styleFields = ["property", "value"];

const textPartFields = [
  "bold",
  {
    name: "color",
    valueFrom: "value",
  },
  "label",
  {name: "_key", rename: "id"},
];

const richTextFields = [
  {
    name: "children",
    fields: ["marks", "text", {name: "_key", rename: "id"}],
  },
  {name: "_key", rename: "id"},
  "style",
];

export const imageField = (name) => ({
  name,
  valueFrom: "image.asset",
});

export const colorField = (name) => ({
  name,
  valueFrom: "value",
});

export const textPartsField = (name, rename) => ({
  name,
  rename,
  fields: textPartFields,
});

export const richTextField = (name, rename) => ({
  name,
  rename,
  fields: richTextFields,
});

export const stylesheetsField = (name) => ({
  name,
  transform: stylesheetTransformer,
  fields: [
    "field",
    {
      name: "styles",
      valueFrom: "properties",
      fields: styleFields,
      transform: styleTransformer,
    },
  ],
});

export const idField = (name) => ({
  name,
  rename: "id",
});

export const heroSectionFields = [
  idField("_id"),
  {
    name: "cta",
    fields: [
      "action",
      colorField("bgColor"),
      colorField("borderColor"),
      colorField("textColor"),
      "cta",
    ],
  },
  richTextField("description"),
  "hasCTA",
  textPartsField("heading"),
  "isLeftImage",
  "name",
  imageField("heroImage"),
  "animation",
  stylesheetsField("styles"),
];

export const heroSectionField = (name) => ({
  name,
  fields: heroSectionFields,
});
