// extracted by mini-css-extract-plugin
export var allTags = "styles-module--allTags--25Kuw";
export var authorDesignation = "styles-module--authorDesignation--1nrMf";
export var authorInfo = "styles-module--authorInfo--1_88b";
export var blogs = "styles-module--blogs--3NqWV";
export var cardArea = "styles-module--cardArea--O1UVw";
export var ctaBetween = "styles-module--ctaBetween--2kjPE";
export var ctaDesc = "styles-module--ctaDesc--34QDA";
export var ctaSection = "styles-module--ctaSection--1db7J";
export var featuredPostMobile = "styles-module--featuredPostMobile--a6TrX";
export var feedHeader = "styles-module--feedHeader--RtKRs";
export var highlight = "styles-module--highlight--3O6Az";
export var leftIcon = "styles-module--leftIcon--1mw8e";
export var loader = "styles-module--loader--URErJ";
export var loaderFeatured = "styles-module--loaderFeatured--1JFaL";
export var loaderTags = "styles-module--loaderTags--TRnBW";
export var mmCtaContainer = "styles-module--mmCtaContainer--1MbgB";
export var postAuthor = "styles-module--postAuthor--2OWax";
export var postDescription = "styles-module--postDescription--3ZoEj";
export var postDetails = "styles-module--postDetails--9rRy2";
export var postInfo = "styles-module--postInfo--34Onp";
export var rightIcon = "styles-module--rightIcon--1lTxN";
export var showMoreButton = "styles-module--showMoreButton--LTPez";
export var subTitle = "styles-module--subTitle--u_76U";
export var tag = "styles-module--tag--1I-EC";
export var tags = "styles-module--tags--iJyOl";
export var tagsWrapper = "styles-module--tagsWrapper--uwxyW";
export var title = "styles-module--title--2rv0w";